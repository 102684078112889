//
// your custom scss goes here
//
.btn {
    font-weight: bolder !important;
    text-transform: uppercase;
}
.slope-right-20deg {
    -webkit-transform: skewX(-15deg) scale(130%) translateX(2%);
    transform: skewX(-15deg) scale(130%) translateX(-10%);
}
.slope-left-15deg {
    -webkit-transform: skewX(15deg) scale(130%) translateX(11.5%);
    transform: skewX(15deg) scale(130%) translateX(13%);
}
.slope-right-10deg {
    -webkit-transform: skewX(-15deg) scale(120%) translateX(-15%);
    transform: skewX(-15deg) scale(120%) translateX(-15%);
}
.slope-left-10deg {
    -webkit-transform: skewX(-15deg) scale(120%) translateX(14%);
    transform: skewX(-15deg) scale(120%) translateX(15%);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-style: italic !important;
    text-transform: uppercase !important;
    font-weight: 700 !important;
    word-break: break-word;
}

.shape:not([class*="shape-blur"]) {
    overflow: hidden;
}
.shape-start {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.shape {
    pointer-events: none;
    position: absolute;
}

.shape-fluid-y > * {
    height: 100%;
    width: auto;
}
.shape-start > * {
    bottom: inherit;
    left: inherit;
    position: inherit;
    top: inherit;
    transform-origin: center right;
}
.shape > * {
    display: block;
}
.shape-end {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.shape-end > * {
    bottom: inherit;
    position: inherit;
    right: inherit;
    top: inherit;
    transform-origin: center left;
}
.text-justify {
    text-align: justify;
}
.btn-warning,
.btn-yellow {
    color: $secondary !important;
}
nav {
    .navbar-brand {
        img {
            width: 187px;
            fill: white;
        }
    }
}
.fw-900 {
    font-weight: 900 !important;
}
.top-strip {
    position: absolute;
    top: 0;
    left: 0;
}
.headroom--unpinned .navbar-sticky,
.offcanvas-push .navbar-sticky {
    background-color: transparent !important;
}
.navbar-light {
    background-color: $white;
}

@media screen and (max-width: 359px) {
    .top-strip {
        display: none;
    }
    nav.navbar {
        padding: 20px 0 !important;
    }
}
@media screen and (max-width: 767px) {
    .amberg-banner {
        padding-top: 90px;
    }
    .slope-right-20deg,
    .slope-left-15deg,
    .slope-left-10deg,
    .slope-right-10deg {
        transform: none !important;
        figure {
            rotate: none !important;
        }
    }
    .footer-menu {
        .list-inline-item {
            display: block !important;
            width: 100% !important;
            margin: 15px 0 !important;
            padding: 0 !important;
            text-align: center;
        }
    }
    .fixed-footer {
        position: relative !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .amberg-banner {
        padding-top: 90px;
    }
    .slope-right-20deg,
    .slope-left-15deg,
    .slope-left-10deg,
    .slope-right-10deg {
        transform: none !important;
        figure {
            rotate: none !important;
        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    h2 {
        font-size: 36px !important;
    }
    .contact {
        .card-header,
        .card-body {
            padding: 20px !important;
        }
    }
    .slope-right-20deg {
        -webkit-transform: skewX(-10deg) scale(130%) translateX(2%);
        transform: skewX(-10deg) scale(130%) translateX(-10%);
    }
    .slope-left-15deg {
        -webkit-transform: skewX(10deg) scale(130%) translateX(11.5%);
        transform: skewX(10deg) scale(130%) translateX(13%);
    }
}
@media screen and (min-width: 992px) {
    .amberg-banner {
        padding-top: 125px;
    }
    .fixed-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}
